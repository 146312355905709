import React from 'react';
import Simple from 'layouts/Simple';
import { OnboardingSection } from './components';
import { useTheme } from '@mui/material/styles';

const OnboardingView = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Simple bgcolor={theme.palette.background.level1}>
      <OnboardingSection />
    </Simple>
  );
};

export default OnboardingView;
