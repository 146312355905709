import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { useTheme } from '@mui/material/styles';
import { Link, useMediaQuery } from '@mui/material';
import { Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { CheckMark } from 'images/SVGs';
import Penning from 'images/ourPartners/Penning.webp';
import * as Styles from './Styles';

const ContentBox = styled(Container)``;
const TextSectionBox = styled(Box)`
  max-width: 583px;
`;
const SubTitle = styled(Typography)`` as StyledPropsFix;
const Text = styled(Typography)`` as StyledPropsFix;
const ProjectLink = styled(Link)``;

const cardContent = [
  {
    id: 1,
    description: 'Registration',
  },
  {
    id: 2,
    src: Penning,
    description: 'Complete KYC',
    link: 'https://www.app.penning.dk/sign-up?referralCode=COREESTATE2023Q1',
  },
  {
    id: 3,
    description: 'Receiving eEUR',
  },
  {
    id: 4,
    description: 'Purchasing on Coreestate.io',
  },
];

const OnboardingSection = (): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <Styles.OnboardingSectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.level1 }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginTop="36px"
      >
        <ContentBox
          className="onboarding-contentBox"
          sx={{ maxWidth: `${sizeOfGrid}px` }}
        >
          <TextSectionBox>
            <SubTitle component="h4" className="onboarding-subTitle">
              Thank You for registration!
            </SubTitle>

            <Divider height={isXs ? '1em' : '2.3em'} />
            <Text component="p" className="onboarding-text">
              Please continue onboarding procedure with Penning to complete KYC
              and receive eEUR.
            </Text>
          </TextSectionBox>

          <Divider height={'2.125rem'} />

          <Styles.CardGrid container spacing={2} rowSpacing={2}>
            {cardContent.map((card, index) => {
              const sidePadding = '2.8em';
              const verticalPadding = '1.5em';

              return (
                <Styles.CardGridContainer
                  item
                  key={card.id}
                  xs={12}
                  sm={6}
                  md={3}
                >
                  <Styles.Card
                    elevation={0}
                    sx={{
                      padding: `${verticalPadding} ${sidePadding}`,
                      paddingLeft: '1.7em',
                      paddingRight: 0,
                    }}
                  >
                    <CheckMark active={index === 0} />
                    {!!card.src && (
                      <Styles.PictureBox style={{ height: '6.4rem' }}>
                        <Styles.Picture
                          src={card.src}
                          component={'img'}
                          height={1}
                          width={1}
                        />
                      </Styles.PictureBox>
                    )}
                    <Box textAlign="center">
                      <Styles.CardDescription>
                        <ClickableDescription link={card.link}>
                          {card.description}
                        </ClickableDescription>
                      </Styles.CardDescription>
                    </Box>
                    <Styles.PointingTriangle />
                  </Styles.Card>
                </Styles.CardGridContainer>
              );
            })}
          </Styles.CardGrid>

          <Divider height={'3.1rem'} />

          <ProjectLink href="/" className="onboarding-link">
            Go back to Coreestate
          </ProjectLink>
        </ContentBox>
        <Divider height={isXs ? '3.3em' : '6.3em'} />
      </Box>
    </Styles.OnboardingSectionStyles>
  );
};

type ClickableDsc = {
  link?: string;
  children: any;
};

function ClickableDescription(props: ClickableDsc) {
  if (!props.link) return <>{props.children}</>;

  return (
    <Styles.ActionButton
      href={props.link}
      variant="contained"
      color="alternate"
    >
      {props.children}
    </Styles.ActionButton>
  );
}

export default OnboardingSection;
