import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { StyledPropsFix } from 'types';
import { Button, Grid, Paper } from '@mui/material';

export const Title = styled(Typography)`
  font-family: Poppins SemiBold;
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 2.1em;
    margin: 1em 2em;
    line-height: 50px;
    text-align: center;
    order: initial;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 1.6em;
    margin-bottom: 1em;
    line-height: 40px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    font-size: 1.2em;
    margin: 0 0 1em 0;
    line-height: inherit;
  }
` as StyledPropsFix;

export const ContentContainer = styled(Container)<{ sizeofgrid?: string }>`
  height: 100;
  position: relative;
  margin: 4em 2em;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    align-items: initial;
    ${({ sizeofgrid }) => sizeofgrid && `max-width: ${sizeofgrid}px;`}
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    align-items: center;
  }
`;

export const ContentBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.background.level1};
`;

export const PictureBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    position: relative;
    width: 8.4em;
    order: initial;
  }

  ${({ theme }) => theme.breakpoints.only('sm')} {
    display: flex;
    position: relative;
    width: 6.4em;
    order: 1;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    display: flex;
    position: relative;
    width: 6.4em;
    order: 1;
  }
`;

export const Picture = styled(Box)`
  object-fit: cover;
` as StyledPropsFix;

export const CardDescription = styled(Box)`
  font-family: Roboto Bold;
  font-size: 1.1em;
  text-align: center;
  line-height: 25px !important;
` as StyledPropsFix;

export const ActionButton = styled(Button)`
  text-transform: none;
  font-family: Roboto Regular;
  color: ${({ theme }) => theme.palette.text.secondary};
  width: min-content;
  align-self: center;
  white-space: nowrap;
  margin-right: 0;
  &:hover {
    background-color: #58a88f;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.125em;
    margin: 0;
    transform: scale(0.7);
  }
` as StyledPropsFix;

export const CardGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up('md')} {
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    width: 100%;
    margin-bottom: 3.6em;
  }

  ${({ theme }) => theme.breakpoints.only('xs')} {
    width: 100%;
    margin-bottom: 3.6em;
    justify-content: center;
  }
` as StyledPropsFix;

export const PointingTriangle = styled(Box)`
  width: 0;
  height: 0;
  border-left: 60px solid white;
  position: absolute;
  right: -3.7rem;
  top: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    border-bottom: 92px solid transparent;
    border-top: 92px solid transparent;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    border-bottom: 145px solid transparent;
    border-top: 145px solid transparent;
  }

  ${({ theme }) => theme.breakpoints.only('xs')} {
    border-bottom: 145px solid transparent;
    border-top: 145px solid transparent;
  }
` as StyledPropsFix;

export const CardGridContainer = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 0 !important;
    margin: -0.7em 0;
    transform: scale(0.93);
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    padding-left: 0 !important;
    margin: -1.2em 0;
    transform: scale(0.87);
  }

  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding-left: 0 !important;
    flex-basis: 52%;
    margin: -1.8em 0;
    transform: scale(0.8);
  }
` as StyledPropsFix;

export const Card = styled(Paper)`
  align-items: center;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 11.6rem;
    width: 12rem;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    height: 18em;
    width: 14em;
    justify-content: center;
  }
` as StyledPropsFix;

export const OnboardingSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 34px !important;
  background: #f8f8f8;

  .onboarding-contentBox {
    display: flex;
    align-items: center;
    padding: 0 1em;
    flex-direction: column;
    max-width: 64rem;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
      margin: 0;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  .onboarding-subTitle {
    font-family: Poppins SemiBold;
    text-align: center;
    line-height: 34px;
    color: ${({ theme }) => theme.palette.text.primary};

    font-size: 2.18em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-bottom: 0;
      font-size: 1.2em;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      line-height: 28px;
    }
  }
  .onboarding-text {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.primary};
    text-align: center;
    font-size: 1.1rem;
    padding-bottom: 1em;
    line-height: 34px;
    margin: 0 6rem;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1em;
      padding-top: 1em;
    }

    ${({ theme }) => theme.breakpoints.only('xs')} {
      line-height: 28px;
      padding-right: 0;
    }
  }
  .onboarding-link {
    font-family: Roboto Regular;
    white-space: nowrap;
    display: inline-block;
    font-size: 1em;
  }
  .onboarding-picture {
    margin-left: 4em;
    margin-top: -6em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-top: -2em;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      margin: 0;
      margin-bottom: 2em;
    }
  }
`;
