import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Location } from '@reach/router';

import { Topbar } from './components';

import { loadFonts /* , useIntersectionObserver */ } from 'utils';
import { customFontList } from 'theme';
import { SiteMaxWithProvider } from 'theme/siteMaxWithContext';

interface Props {
  children: React.ReactNode;
  colorInvert?: boolean;
  bgcolor?: string;
  sectionTitle?: string;
}

const Main = ({
  children,
  bgcolor,
}: // bgcolor = 'transparent',
Props): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // Start page from top on load
  useEffect(() => {
    try {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      };
    } catch (error) {
      // do nothing
    }
  }, []);

  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    loadFonts(Object.keys(customFontList), () => setFontsLoaded(true));
  }, []);

  const anchorRef = React.useRef(null);

  return (
    <Location>
      {() =>
        fontsLoaded && (
          <SiteMaxWithProvider>
            <Box sx={{ backgroundColor: theme.palette.background.level1 }}>
              <Topbar />
              <main style={{ backgroundColor: bgcolor }}>
                {children}
                <Divider />
              </main>

              <div
                ref={anchorRef}
                style={{
                  position: 'absolute',
                  width: '1px',
                  height: '1px',
                  top: '756px', // height of Hero section
                  left: 0,
                }}
              />
            </Box>
          </SiteMaxWithProvider>
        )
      }
    </Location>
  );
};

export default Main;
