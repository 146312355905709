import * as React from 'react';
import Box from '@mui/material/Box';
import siteLogo from 'images/logo-traced.svg';
import { styled } from '@mui/system';

import Link from 'layouts/Main/components/Footer/FooterLink';
import { StyledPropsFix } from 'types';
import { useTheme } from '@mui/material/styles';

const Logo = styled(Box)`` as StyledPropsFix;

const Topbar = (): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <Box
        flex="1"
        padding="4rem 0"
        display="flex"
        justifyContent="center"
        alignContent="center"
        sx={{ backgroundColor: theme.palette.background.level1 }}
      >
        <Link href="/">
          <Box height={{ xs: '30px', sm: '40px', md: '54px' }}>
            <Logo
              src={siteLogo}
              component={'img'}
              height={1}
              alt="Coreestate tokenized consumer rentals logo"
            />
          </Box>
        </Link>
      </Box>
    </>
  );
};

export default Topbar;
