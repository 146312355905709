import React from 'react';
import Onboarding from 'views/Onboarding';
import { SEO as Head } from 'components/seo';

const OnboardingPage = (): JSX.Element => {
  return (
    <>
      <Head />
      <Onboarding />
    </>
  );
};

export default OnboardingPage;
